import React, { useEffect, useState } from "react";
import {
	Divider,
	Table,
	Grid,
	Button,
	Select,
	MenuItem,
	Container,
	Stack,
	Box,
	InputLabel,
	Typography,
	TableHead,
	TableRow,
	TableCell,
	useTheme,
	TableBody,
	Link,
	TextField,
	FormControl,
	Checkbox,
	ListItemText,
	Chip,
	Alert,
	FormHelperText,
} from "@mui/material";
import { useUser } from "@clerk/clerk-react";
import Cookies from "js-cookie";
import useFetchUserData from "../../../hooks/useFetchUserData";
import useFetchUserDataById from "../../../hooks/useFetchUserDataById";
import useFetchLocations from "../../../hooks/useFetchLocations";
import useCreateUser from "../../../hooks/useCreateUser";
import { useParams } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";

export default function Users({ loading }) {
	const { isSignedIn, user, isLoaded } = useUser();
	const [chosenLocation, setChosenLocation] = useState(0);
	const [chosenOrder, setChosenOrder] = useState(0);
	const [result, setResult] = useState(null);
	const [orderType, setOrderType] = useState("choose");
	const [orders, setOrders] = useState([]);
	const theme = useTheme();
	// const accounts=[{username:'1',name:'1'}]
	// // const { accounts } = useMsal();
	// const username = accounts[0] && accounts[0].username;
	// const name = accounts[0] && accounts[0].name;
	const location_id = Cookies.get("location_id");
	const [listLocations, setListLocations] = useState([]);
	const [users, setUsers] = useState([]);
	// console.log("user",user.emailAddresses[0].emailAddress)
	// console.log("level",user?.publicMetadata?.level)

	const { userData, userLevel, locationId, allowedLocations } =
		useFetchUserData(user.emailAddresses[0].emailAddress);

	const locations = useFetchLocations(userLevel);
	const { createUser, response, error, isLoading } = useCreateUser();

	const { u } = useParams();
	const {
		userEmailById,
		userDataById,
		userLevelById,
		userLocationIdById,
		userAllowedLocationsById,
	} = useFetchUserDataById();
	// console.log("userData",userData)
	// console.log("userLocation",locationId)
	// console.log("userLevel",userLevel)
	// console.log("userallowedLocations",allowedLocations)

	// const handleSubmit = (output) => {
	//   fetch(`${process.env.REACT_APP_API_ENDPOINT}/reports`,{
	//     method: 'POST',
	//     headers: {
	//       'Content-Type': 'application/json'
	//     },
	//     body: JSON.stringify({
	//       location_id: chosenLocation,
	//       order_type: orderType,
	//       order_date: chosenOrder,
	//       output: output
	//     })
	//   }).then(res => res.json()).then(data => {
	//     console.log("data",data)
	//     setResults(data)
	//   }).catch(err => console.log(err))
	// };

	//   //GET ORDERS----
	//   useEffect(() => {
	//     fetch(`${process.env.REACT_APP_API_ENDPOINT}/users`).then(res => res.json()).then(data => {
	//       setUsers(data)
	//     })
	//   },[])

	const validationSchema = yup.object({
		email: yup
			.string("Enter your email")
			.email("Enter a valid email")
			.required("Email is required"),
		level: yup.string("Select your level").required("Level is required"),
		branchLocation: yup
			.string("Select your branch location")
			.required("Branch location is required"),
		manageLocations: yup
			.array()
			.min(1, "Select at least one location to manage"),
	});

	const levels = {
		user: "Branch User",
		admin: "Admin",
		dom: "Division Operations Manager",
		hod: "Head of Division",
	};

	const formik = useFormik({
		initialValues: {
			email: "",
			level: "",
			branchLocation: "",
			manageLocations: [],
		},
		validationSchema: validationSchema,
		onSubmit: (values, { resetForm }) => {
			// console.log(JSON.stringify(values, null, 2));
			const id = u;
			const email = values.email;
			const level = values.level;
			const branchLocation = values.branchLocation;
			const manageLocationsArray = values.manageLocations;
			const manageLocations = manageLocationsArray.join(",");

			createUser({ email, level, branchLocation, manageLocations });
			resetForm();
			// Perform the fetch call to '/createUser'
		},
	});
	// console.log("response",userAllowedLocationsById)
	// console.log(locations)
	// console.log("form",formik.values.manageLocations)
	//	console.log("response", response);
	return (
		<Container maxWidth="sm" sx={{ background: "#FFF", p: 3, borderRadius: 2 }}>
			<Typography variant="h5" sx={{ mb: 2 }}>
				Add User
			</Typography>
			{response?.results?.affectedRows > 0 ? (
				<Alert severity="success" sx={{ mb: 2 }}>
					The user was created
				</Alert>
			) : error ? (
				<Alert severity="error" sx={{ mb: 2 }}>
					{error.toString()}
				</Alert>
			) : null}
			<form onSubmit={formik.handleSubmit}>
				<Stack direction="column" spacing={3}>
					<TextField
						fullWidth
						id="email"
						name="email"
						label="Email"
						value={formik.values.email}
						onChange={formik.handleChange}
						error={formik.touched.email && Boolean(formik.errors.email)}
						helperText={formik.touched.email && formik.errors.email}
					/>
					<FormControl fullWidth>
						<InputLabel id="demo-simple-select-label">Level</InputLabel>

						<Select
							elId="demo-simple-select-label"
							id="level"
							name="level"
							value={formik.values.level}
							label="Level"
							onChange={formik.handleChange}
							error={formik.touched.level && Boolean(formik.errors.level)}
							helperText={formik.touched.level && formik.errors.level}
						>
							{Object.entries(levels).map(([key, value]) => (
								<MenuItem key={key} value={key}>
									{value}
								</MenuItem>
							))}
						</Select>
						{formik.touched.level && formik.errors.level && (
							<FormHelperText error>{formik.errors.level}</FormHelperText>
						)}
					</FormControl>
					<FormControl fullWidth>
						<InputLabel id="select-location-label">Branch Location</InputLabel>
						<Select
							labelId="select-location-label"
							id="branchLocation"
							name="branchLocation"
							InputLabelProps={{ shrink: true }}
							value={formik.values.branchLocation}
							label="Branch Location"
							onChange={formik.handleChange}
							error={
								formik.touched.branchLocation &&
								Boolean(formik.errors.branchLocation)
							}
						>
							{locations.map((location) => (
								<MenuItem key={location.id} value={location.id}>
									{location.name}
								</MenuItem>
							))}
						</Select>
						{formik.touched.branchLocation && formik.errors.branchLocation && (
							<FormHelperText error>
								{formik.errors.branchLocation}
							</FormHelperText>
						)}
					</FormControl>
					{(userLevel === "hod" ||
						userLevel === "dom" ||
						userLevel === "admin" ||
						userLevel === "superadmin") && (
						<FormControl fullWidth>
							<InputLabel>Manage Locations</InputLabel>
							<Select
								label="ManageLocations"
								id="manageLocations"
								name="manageLocations"
								multiple
								InputLabelProps={{ shrink: true }}
								value={formik.values.manageLocations}
								onChange={formik.handleChange}
								renderValue={(selectedIds) => {
									const selectedNames = selectedIds.map(
										(id) => locations.find((loc) => loc.id === id)?.name
									);
									return (
										<Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
											{selectedNames.map((value) => (
												<Chip key={value} label={value} />
											))}
										</Box>
									);
								}}

								// renderValue={(selectedIds) => {

								//   const selectedNames = selectedIds.map(id => locations.find(loc => loc.id === id)?.name);
								//   console.log("Selected ID:", selectedIds, "Selected Name:", selectedNames);
								//   return selectedNames;
								// }}
							>
								{locations.map((location) => (
									<MenuItem key={location.id} value={location.id}>
										<Checkbox
											checked={
												formik.values.manageLocations.indexOf(location.id) > -1
											}
										/>
										<ListItemText primary={location.name} />
									</MenuItem>
								))}
							</Select>
							{formik.touched.manageLocations &&
								formik.errors.manageLocations && (
									<FormHelperText error>
										{formik.errors.manageLocations}
									</FormHelperText>
								)}
						</FormControl>
					)}
					<Button color="primary" variant="contained" fullWidth type="submit">
						Submit
					</Button>
				</Stack>
			</form>
		</Container>
	);
}
