import { useState } from "react";

function useEditProduct() {
	const [response, setResponse] = useState();
	const [updateResponse, setUpdateResponse] = useState();
	const [error, setError] = useState();
	const [isLoading, setIsLoading] = useState(false);

	const editProduct = async (productData) => {
		setIsLoading(true);
		setError(null);

		try {
			const response = await fetch(
				`${process.env.REACT_APP_API_ENDPOINT}/productsUpdate`,
				{
					method: "PUT",
					body: JSON.stringify(productData),
					headers: {
						"Content-Type": "application/json",
					},
				}
			);

			const data = await response.json();
			setUpdateResponse(data);
		} catch (err) {
			setError(err.message);
		} finally {
			setIsLoading(false);
		}
	};

	const getProduct = async (id) => {
		setIsLoading(true);
		setError(null);

		try {
			const response = await fetch(
				`${process.env.REACT_APP_API_ENDPOINT}/products/${id}`,
				{
					cache: "no-store",
				}
			);
			const data = await response.json();
			setResponse(data);
		} catch (err) {
			setError(err.message);
		} finally {
			setIsLoading(false);
		}
	};

	return {
		getProduct,
		editProduct,
		response,
		updateResponse,
		error,
		isLoading,
	};
}

export default useEditProduct;
