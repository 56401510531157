"use client";
import React, { useEffect } from "react";
// import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from "@azure/msal-react";
// import ProfileContent from "./components/auth/ProfileContent";
import "./styles/App.css";
import Layout from "./theme/layout/Layout";
import ShopFront from "./components/shop";
import Reports from "./components/reports";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Stack } from "@mui/material";
import { light } from "@clerk/themes";

import {
	SignOutButton,
	SignInButton,
	SignedIn,
	SignedOut,
	useUser,
} from "@clerk/clerk-react";
import { dark } from "@clerk/themes";

// Import your publishable key
const PUBLISHABLE_KEY = process.env.REACT_APP_CLERK_PUBLISHABLE_KEY;

if (!PUBLISHABLE_KEY) {
	throw new Error("Missing Publishable Key");
}
import { ClerkProvider } from "@clerk/clerk-react";
import SignInForm from "./components/SignInForm";
import DashOut from "./components/DashOut";
import SignUp from "./components/SignUp";
import Users from "./components/users";
import EditUser from "./components/users/editUser";
import CreateUser from "./components/users/createUser";
import CreateLocation from "./components/locations/createLocation";
import EditLocation from "./components/locations/editLocation";
import Locations from "./components/locations";
import ProductsList from "./components/products/";
import CreateProduct from "./components/products/create";
import EditProduct from "./components/products/edit";
export default function App() {
	// console.log("user", user)
	// const { accounts } = useMsal();
	// console.log("asd", isSignedIn)
	// const accounts = [{ username: '1', name: '1' }]
	// const username = accounts[0] && accounts[0].username;
	const username = "undefined";

	return (
		<ClerkProvider
			appearance={{
				baseTheme: light,
			}}
			publishableKey={PUBLISHABLE_KEY}
		>
			<Layout>
				<SignedIn>
					<BrowserRouter>
						<Routes>
							<Route path="/" element={<ShopFront />} />
							<Route path="/reports" element={<Reports />} />
							<Route path="/users" element={<Users />} />
							<Route path="/locations" element={<Locations />} />
							<Route path="/products" element={<ProductsList />} />
							<Route path="/editUser/:u" element={<EditUser />} />
							<Route path="/createUser" element={<CreateUser />} />
							<Route path="/createLocation" element={<CreateLocation />} />
							<Route path="/createProduct" element={<CreateProduct />} />
							<Route path="/editLocation/:u" element={<EditLocation />} />
							<Route path="/editProduct/:id" element={<EditProduct />} />
						</Routes>
					</BrowserRouter>
				</SignedIn>
				<SignedOut>
					<Stack
						alignItems="center"
						justifyContent="flex-start"
						sx={{ height: "100vh", width: "100%" }}
					>
						<BrowserRouter>
							<Routes>
								<Route path="/sign-up" element={<SignUp />} />
								<Route path="/" element={<DashOut />} />
							</Routes>
						</BrowserRouter>
					</Stack>
				</SignedOut>
			</Layout>
		</ClerkProvider>
	);
}
