import { useState, useEffect } from "react";

function useFetchLocations(userLevel = null) {
	const [locations, setLocations] = useState([]);

	useEffect(() => {
		if (userLevel === null) {
			fetch(`${process.env.REACT_APP_API_ENDPOINT}/locations`)
				.then((res) => res.json())
				.then((data) => {
					setLocations(data);
				});
		} else {
			fetch(`${process.env.REACT_APP_API_ENDPOINT}/locations`, {
				cache: "no-store",
			})
				.then((res) => res.json())
				.then((data) => {
					setLocations(data);
				});
		}
	}, [userLevel]);

	return locations;
}
export default useFetchLocations;
