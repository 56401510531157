import { useState } from "react";

function useCreateLocation(locationData) {
	const [response, setResponse] = useState();
	const [error, setError] = useState();
	const [isLoading, setIsLoading] = useState(false);

	const createLocation = (locationData) => {
		//  console.log("locationData",locationData)
		setIsLoading(true);
		fetch(`${process.env.REACT_APP_API_ENDPOINT}/locationCreate`, {
			method: "POST",
			body: JSON.stringify(locationData),
			headers: {
				"Content-Type": "application/json",
			},
		})
			.then((response) => response.json())
			.then((data) => {
				setResponse(data);
				setIsLoading(false);
			})
			.catch((err) => {
				setError(err);
				setIsLoading(false);
			});
	};
	return { createLocation, response, error, isLoading };
}
export default useCreateLocation;
