import React, { useEffect, useState } from "react";
import {
	Divider,
	Table,
	Grid,
	Button,
	Select,
	MenuItem,
	Container,
	Stack,
	Box,
	InputLabel,
	Typography,
	TableHead,
	TableRow,
	TableCell,
	useTheme,
	TableBody,
	Link,
	TextField,
	FormControl,
	Checkbox,
	ListItemText,
	Chip,
	Alert,
	CircularProgress,
} from "@mui/material";
import { useUser } from "@clerk/clerk-react";
import Cookies from "js-cookie";
import useFetchUserData from "../../../hooks/useFetchUserData";
import useFetchUserDataById from "../../../hooks/useFetchUserDataById";
import useFetchCategories from "../../../hooks/useFetchCategories";
import useFetchLocations from "../../../hooks/useFetchLocations";
import useEditProduct from "../../../hooks/useEditProduct";
import { useParams } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import { storage } from "../../../utils/firebase";

export default function EditProduct({ loading }) {
	const {
		editProduct,
		response,
		updateResponse,
		error,
		isLoading,
		getProduct,
	} = useEditProduct();
	const { userData, userLevel, locationId, allowedLocations } =
		useFetchUserData();
	const { categories } = useFetchCategories();

	const { id } = useParams();

	const [locations, setLocations] = useState([]);
	const [productLocations, setProductLocations] = useState([]);
	const [product, setProduct] = useState(null);
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [localError, setError] = useState(null);

	const preDefinedLocationIds = ["100", "101", "1000"];
	useEffect(() => {
		fetch(`${process.env.REACT_APP_API_ENDPOINT}/locations`, {
			cache: "no-store",
		})
			.then((res) => res.json())
			.then((data) => {
				setLocations(
					data.filter(
						(location) =>
							!preDefinedLocationIds.includes(location.id.toString())
					)
				);
			});
	}, []);
	useEffect(() => {
		if (id) {
			getProduct(id);
			console.log("GET PRODUCTS");
		}
	}, [id]);
	useEffect(() => {
		if (response) {
			setProduct(response);
			const filteredLocs = response.locations
				.split(",")
				.filter((location) => !["100", "101", "1000"].includes(location));
			const locationIds = filteredLocs.map((loc) => loc.split("-")[0]);
			//	console.log("locationIds", locationIds);
			formik.setValues({
				name: response.name,
				category: response.category_id,
				locations: locationIds,
				quantities: response.quantities,
				order: response.order,
				image: response.image,
			});
		}
	}, [response]);

	const [imageFile, setImageFile] = useState(null);

	const handleImageUpload = (event) => {
		setImageFile(event.target.files[0]);
	};

	const formik = useFormik({
		initialValues: {
			name: "",
			category: "",
			locations: [],
			quantities: "",
			order: 0,
			image: "",
		},
		validationSchema: yup.object({
			name: yup.string().required("Product name is required"),
			category: yup.number().required("Category is required"),
			locations: yup.array().min(1, "At least one location is required"),
			quantities: yup.string().required("Quantities are required"),
			order: yup.number().required("Order is required"),
			//image: yup.string().url("Image URL is not valid"),
		}),
		onSubmit: async (values, { resetForm }) => {
			setIsSubmitting(true);
			setError(null);

			try {
				// Upload image to Firebase Storage
				let imageUrl = values.image;
				if (imageFile) {
					const imageRef = storage.ref(`products/${imageFile.name}`);
					await imageRef.put(imageFile);
					imageUrl = await imageRef.getDownloadURL();
					console.log("Image uploaded successfully:", imageUrl);
				}

				// Edit the product
				await editProduct({
					id: product.id,
					name: values.name,
					category_id: values.category,
					locations: values.locations.join(","),
					quantities: values.quantities,
					order: values.order,
					image: imageUrl,
				});
				console.log("PRODUCT UPDATED");
				setIsSubmitting(false);
				getProduct(id);
				console.log("REFRESH PRODUCT");
				resetForm();
			} catch (error) {
				console.error("Error uploading image or editing product:", error);
				setError(error.message);
				setIsSubmitting(false);
			}
		},
	});

	if (!product) {
		return <div>Loading...</div>;
	}
	//console.log("product", product);

	return (
		<Container maxWidth="sm" sx={{ background: "#FFF", p: 3, borderRadius: 2 }}>
			{updateResponse && updateResponse.affectedRows > 0 && (
				<Alert severity="success" sx={{ mb: 2 }}>
					The product was updated
				</Alert>
			)}

			{localError && (
				<Alert severity="error" sx={{ mb: 2 }}>
					{error}
				</Alert>
			)}

			<Typography variant="h5" sx={{ mb: 2 }}>
				Edit Product
			</Typography>
			<form onSubmit={formik.handleSubmit}>
				<Stack direction="column" spacing={3}>
					<TextField
						fullWidth
						id="name"
						name="name"
						label="Product Name"
						InputLabelProps={{ shrink: true }}
						value={formik.values.name}
						onChange={formik.handleChange}
						error={formik.touched.name && Boolean(formik.errors.name)}
						helperText={formik.touched.name && formik.errors.name}
					/>
					<FormControl fullWidth>
						<InputLabel id="category-label">Category</InputLabel>
						<Select
							labelId="category-label"
							id="category"
							name="category"
							value={formik.values.category}
							onChange={formik.handleChange}
							error={formik.touched.category && Boolean(formik.errors.category)}
						>
							<MenuItem value="">Select a category</MenuItem>
							{categories &&
								categories.map((category) => (
									<MenuItem
										key={category.categoryId}
										value={category.categoryId}
									>
										{category.categoryName}
									</MenuItem>
								))}
						</Select>
						{formik.touched.category && formik.errors.category && (
							<Typography color="error">{formik.errors.category}</Typography>
						)}
					</FormControl>
					<FormControl fullWidth>
						<InputLabel id="locations-label">Locations</InputLabel>
						<Select
							multiple
							labelId="locations-label"
							id="locations"
							name="locations"
							value={formik.values.locations}
							onChange={formik.handleChange}
							error={
								formik.touched.locations && Boolean(formik.errors.locations)
							}
							renderValue={(selected) => (
								<Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
									{selected.map((value) => (
										<Chip
											key={value}
											label={
												locations.find((l) => l.id === value)?.name || value
											}
										/>
									))}
								</Box>
							)}
						>
							{locations &&
								locations.map((location) => (
									<MenuItem key={location.id} value={location.id.toString()}>
										<Checkbox
											checked={formik.values.locations.includes(
												location.id.toString()
											)}
										/>
										<ListItemText primary={location.name} />
									</MenuItem>
								))}
						</Select>
						{formik.touched.locations && formik.errors.locations && (
							<Typography color="error">{formik.errors.locations}</Typography>
						)}
					</FormControl>
					<TextField
						fullWidth
						id="quantities"
						name="quantities"
						label="Quantities"
						InputLabelProps={{ shrink: true }}
						value={formik.values.quantities}
						onChange={formik.handleChange}
						error={
							formik.touched.quantities && Boolean(formik.errors.quantities)
						}
						helperText={formik.touched.quantities && formik.errors.quantities}
					/>
					<TextField
						fullWidth
						id="order"
						name="order"
						label="Order"
						type="number"
						InputLabelProps={{ shrink: true }}
						value={formik.values.order}
						onChange={formik.handleChange}
						error={formik.touched.order && Boolean(formik.errors.order)}
						helperText={formik.touched.order && formik.errors.order}
					/>
					<TextField
						fullWidth
						id="image"
						name="image"
						label="Product Image"
						type="file"
						InputLabelProps={{ shrink: true }}
						onChange={handleImageUpload}
					/>

					{product.image && (
						<Box
							component="img"
							src={
								product.image.startsWith("https")
									? product.image
									: `/products/${product.image}`
							}
							sx={{ width: "100%", maxWidth: 200 }}
						/>
					)}

					<Button
						color="primary"
						variant="contained"
						fullWidth
						type="submit"
						disabled={isSubmitting}
					>
						{isSubmitting ? (
							<CircularProgress size={24} color="inherit" />
						) : (
							"Update"
						)}
					</Button>
				</Stack>
			</form>
		</Container>
	);
}
