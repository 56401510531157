import firebase from "firebase/compat/app";
import "firebase/compat/storage";
// Replace with your Firebase configuration details
const firebaseConfig = {
	apiKey: "AIzaSyAA4G1s0S_Y4qcFIg3lfsRrRkWwdBYxm5I",
	authDomain: "order-system-cefea.firebaseapp.com",
	projectId: "order-system-cefea",
	storageBucket: "order-system-cefea.firebasestorage.app",
	messagingSenderId: "799713834700",
	appId: "1:799713834700:web:a292a436bc7c3cc37db469",
};

firebase.initializeApp(firebaseConfig);

// Export the Firebase storage service
export const storage = firebase.storage();

export default firebase;
