import { useState } from "react";

function useCreateProduct() {
	const [response, setResponse] = useState();
	const [error, setError] = useState();
	const [isLoading, setIsLoading] = useState(false);

	const createProduct = (productData) => {
		setIsLoading(true);

		const finalLocations = [productData.locations, "100", "101", "1000"].join(
			","
		);

		fetch(`${process.env.REACT_APP_API_ENDPOINT}/productsCreate`, {
			method: "POST",
			body: JSON.stringify({ ...productData, locations: finalLocations }),
			headers: {
				"Content-Type": "application/json",
			},
		})
			.then((response) => response.json())
			.then((data) => {
				setResponse(data);
				setIsLoading(false);
			})
			.catch((err) => {
				setError(err);
				setIsLoading(false);
			});
	};

	return { createProduct, response, error, isLoading };
}

export default useCreateProduct;
