import React, { useEffect, useState } from "react";
import {
	Table,
	Grid,
	Container,
	Box,
	Typography,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
	Link,
	Chip,
	Stack,
	Button,
	Alert,
	Paper,
} from "@mui/material";
import { useUser } from "@clerk/clerk-react";
import DeleteLeft from "src/theme/icons/DeleteLeft";
import Modal from "@mui/material/Modal";

const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: 400,
	bgcolor: "background.paper",
	border: "2px solid #000",
	boxShadow: 24,
	p: 4,
};

export default function Products({ loading }) {
	const { isSignedIn, user, isLoaded } = useUser();
	const [results, setResults] = useState(null);
	const [products, setProducts] = useState(null);

	const handleSubmit = (output) => {
		fetch(`${process.env.REACT_APP_API_ENDPOINT}/products`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify({
				product_id: chosenProduct,
				order_type: orderType,
				order_date: chosenOrder,
				output: output,
			}),
		})
			.then((res) => res.json())
			.then((data) => {
				console.log("data", data);
				setResults(data);
			})
			.catch((err) => console.log(err));
	};

	const [open, setOpen] = React.useState(false);
	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);

	const [deleteId, setDeleteId] = useState(null);
	const [deleteProduct, setDeleteProduct] = useState(null);
	const [deleteStatus, setDeleteStatus] = useState(false);

	const handleDelete = async (productId, productName) => {
		setOpen(true);
		setDeleteId(productId);
		setDeleteProduct(productName);
	};
	const getProducts = async () => {
		fetch(`${process.env.REACT_APP_API_ENDPOINT}/products`)
			.then((res) => res.json())
			.then((data) => {
				if (Array.isArray(data.products)) {
					setProducts(data.products);
				} else {
					setProducts([]);
				}
			})
			.catch((err) => console.log(err));
	};

	const handleCompleteDelete = async (productId) => {
		//	console.log("id", productId);
		fetch(`${process.env.REACT_APP_API_ENDPOINT}/productDelete/${deleteId}`, {
			method: "DELETE",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify({
				id: productId,
			}),
		})
			.then((res) => res.json())
			.then((data) => {
				//console.log("data", data);
				getProducts();
				setDeleteStatus(true);
			})
			.catch((err) => console.log(err));
	};

	// GET PRODUCTS
	useEffect(() => {
		!products && getProducts();
	}, []);

	return (
		<Container maxWidth="lg">
			<div>
				<Modal
					open={open}
					onClose={handleClose}
					aria-labelledby="modal-modal-title"
					aria-describedby="modal-modal-description"
				>
					<Box sx={style}>
						{deleteStatus && (
							<>
								<Alert severity="success" sx={{ mb: 2 }}>
									The product has been deleted.
								</Alert>
								<Button
									sx={{ background: "#CCC" }}
									variant="contained"
									onClick={handleClose}
								>
									Close Window
								</Button>
							</>
						)}
						{deleteStatus == false && (
							<>
								<Typography
									sx={{ fontWeight: 600 }}
									variant="h5"
									component="h5"
								>
									Delete Product
								</Typography>
								<Typography id="modal-modal-description" sx={{ mt: 2 }}>
									Are you sure want to delete <strong>{deleteProduct}</strong>?
								</Typography>
								<Stack
									direction="row"
									justifyContent="space-between"
									sx={{ mt: 2 }}
								>
									<Button
										sx={{ background: "#CCC" }}
										variant="contained"
										onClick={handleClose}
									>
										Cancel
									</Button>
									<Button
										color="primary"
										variant="contained"
										onClick={() => handleCompleteDelete(deleteId)}
									>
										Delete
									</Button>
								</Stack>
							</>
						)}
					</Box>
				</Modal>
			</div>
			<Grid container>
				<Grid item={true} xs={12} sm={12} md={12} lg={12}>
					<>
						<Stack direction="row" justifyContent="flex-end">
							<Button
								href="/createProduct"
								variant="contained"
								sx={{ mb: 2, cursor: "pointer", "&:hover": { color: "#FFF" } }}
							>
								Create Product
							</Button>
						</Stack>
						{products && products.length === 0 ? (
							<Box sx={{ p: 2 }}>
								<Typography>There are no products</Typography>
							</Box>
						) : (
							<Paper>
								<Table>
									<TableHead>
										<TableRow>
											<TableCell sx={{ fontWeight: "bold" }}>Name</TableCell>
											<TableCell sx={{ width: 10 }}>Delete</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{products &&
											products.map((product, index) => (
												<TableRow key={index} sx={{ verticalAlign: "top" }}>
													<TableCell sx={{ flex: 1 }}>
														<Link href={`/editProduct/${product.id}`}>
															{product.name}
														</Link>
													</TableCell>
													<TableCell sx={{ width: 10 }}>
														<DeleteLeft
															sx={{
																cursor: "pointer",
																"&:hover": {
																	opacity: 0.5,
																	transition: "all 0.3s ease-in-out",
																},
															}}
															onClick={() =>
																handleDelete(product.id, product.name)
															}
														/>
													</TableCell>
												</TableRow>
											))}
									</TableBody>
								</Table>
							</Paper>
						)}
					</>
				</Grid>
			</Grid>
		</Container>
	);
}
