import React, { useEffect, useState } from "react";
import {
	Divider,
	Table,
	Grid,
	Button,
	Select,
	MenuItem,
	Container,
	Stack,
	Box,
	InputLabel,
	Typography,
	TableHead,
	TableRow,
	TableCell,
	useTheme,
	TableBody,
	Link,
	TextField,
	FormControl,
	Checkbox,
	ListItemText,
	Chip,
	Alert,
	CircularProgress,
} from "@mui/material";
import { useUser } from "@clerk/clerk-react";
import Cookies from "js-cookie";
import useFetchUserData from "../../../hooks/useFetchUserData";
import useFetchUserDataById from "../../../hooks/useFetchUserDataById";
import useFetchCategories from "../../../hooks/useFetchCategories";
import useFetchLocations from "../../../hooks/useFetchLocations";
import useCreateProduct from "../../../hooks/useCreateProduct";
import { useParams } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import { storage } from "../../../utils/firebase";

export default function CreateProduct({ loading }) {
	const { createProduct, response } = useCreateProduct();

	const { userData, userLevel, locationId, allowedLocations } =
		useFetchUserData();
	const { categories } = useFetchCategories();

	const [locations, setLocations] = useState([]);
	useEffect(() => {
		fetch(`${process.env.REACT_APP_API_ENDPOINT}/locations`)
			.then((res) => res.json())
			.then((data) => {
				const preDefinedLocationIds = ["100", "101", "1000"];
				setLocations(
					data.filter(
						(location) =>
							!preDefinedLocationIds.includes(location.id.toString())
					)
				);
			});
	}, []);

	const [quantities, setQuantities] = useState("");
	const [imageFile, setImageFile] = useState(null);

	const handleImageUpload = (event) => {
		setImageFile(event.target.files[0]);
	};
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [error, setError] = useState(null);

	const formik = useFormik({
		initialValues: {
			name: "",
			category: "",
			locations: [],
			quantities: "",
			order: 0,
			image: "",
		},
		validationSchema: yup.object({
			name: yup.string().required("Product name is required"),
			category: yup.number().required("Category is required"),
			locations: yup.array().min(1, "At least one location is required"),
			quantities: yup.string().required("Quantities are required"),
			order: yup.number().required("Order is required"),
			image: yup.string().url("Image URL is not valid"),
		}),
		onSubmit: async (values, { resetForm }) => {
			setIsSubmitting(true);
			setError(null);

			const { name, category, locations, quantities, order, image } = values;

			try {
				// Upload image to Firebase Storage
				const imageRef = storage.ref(`products/${imageFile.name}`);
				await imageRef.put(imageFile);
				const imageUrl = await imageRef.getDownloadURL();

				// Create the product
				await createProduct({
					name,
					category_id: category,
					locations: locations.join(","),
					quantities,
					order,
					image: imageUrl,
				});
				setIsSubmitting(false);
				resetForm();
			} catch (error) {
				console.error("Error uploading image or creating product:", error);
				setError(error.message);
				console.error("Error uploading image or creating product:", error);
				setIsSubmitting(false);
			}
		},
	});

	const handleQuantitiesChange = (event) => {
		setQuantities(event.target.value);
	};

	return (
		<Container maxWidth="sm" sx={{ background: "#FFF", p: 3, borderRadius: 2 }}>
			{response && response.affectedRows > 0 && (
				<Alert severity="success" sx={{ mb: 2 }}>
					The product was created
				</Alert>
			)}

			{error && (
				<Alert severity="error" sx={{ mb: 2 }}>
					{error}
				</Alert>
			)}
			<Typography variant="h5" sx={{ mb: 2 }}>
				Add Product
			</Typography>
			<form onSubmit={formik.handleSubmit}>
				<Stack direction="column" spacing={3}>
					<TextField
						fullWidth
						id="name"
						name="name"
						label="Product Name"
						InputLabelProps={{ shrink: true }}
						value={formik.values.name}
						onChange={formik.handleChange}
						error={formik.touched.name && Boolean(formik.errors.name)}
						helperText={formik.touched.name && formik.errors.name}
					/>

					<FormControl fullWidth>
						<InputLabel id="category-label">Category</InputLabel>
						<Select
							labelId="category-label"
							id="category"
							name="category"
							value={formik.values.category}
							onChange={formik.handleChange}
							error={formik.touched.category && Boolean(formik.errors.category)}
						>
							<MenuItem value="">Select a category</MenuItem>
							{categories &&
								categories.map((category) => (
									<MenuItem
										key={category.categoryId}
										value={category.categoryId}
									>
										{category.categoryName}
									</MenuItem>
								))}
						</Select>
						{formik.touched.category && formik.errors.category && (
							<Typography color="error">{formik.errors.category}</Typography>
						)}
					</FormControl>

					<FormControl fullWidth>
						<InputLabel id="locations-label">Locations</InputLabel>
						<Select
							multiple
							labelId="locations-label"
							id="locations"
							name="locations"
							value={formik.values.locations}
							onChange={formik.handleChange}
							error={
								formik.touched.locations && Boolean(formik.errors.locations)
							}
							renderValue={(selected) => (
								<Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
									{selected.map((value) => (
										<Chip
											key={value}
											label={locations.find((l) => l.id === value)?.name}
										/>
									))}
								</Box>
							)}
						>
							{locations &&
								locations.map((location) => (
									<MenuItem key={location.id} value={location.id}>
										<Checkbox
											checked={formik.values.locations.includes(location.id)}
										/>
										<ListItemText primary={location.name} />
									</MenuItem>
								))}
						</Select>
						{formik.touched.locations && formik.errors.locations && (
							<Typography color="error">{formik.errors.locations}</Typography>
						)}
					</FormControl>

					<TextField
						fullWidth
						id="quantities"
						name="quantities"
						label="Quantities"
						InputLabelProps={{ shrink: true }}
						value={formik.values.quantities}
						onChange={formik.handleChange}
						error={
							formik.touched.quantities && Boolean(formik.errors.quantities)
						}
						helperText={formik.touched.quantities && formik.errors.quantities}
					/>

					<TextField
						fullWidth
						id="order"
						name="order"
						label="Order"
						type="number"
						InputLabelProps={{ shrink: true }}
						value={formik.values.order}
						onChange={formik.handleChange}
						error={formik.touched.order && Boolean(formik.errors.order)}
						helperText={formik.touched.order && formik.errors.order}
					/>

					{/* <TextField
						fullWidth
						id="image"
						name="image"
						label="Image URL"
						InputLabelProps={{ shrink: true }}
						value={formik.values.image}
						onChange={formik.handleChange}
						error={formik.touched.image && Boolean(formik.errors.image)}
						helperText={formik.touched.image && formik.errors.image}
					/> */}
					<TextField
						fullWidth
						id="image"
						name="image"
						label="Product Image"
						type="file"
						InputLabelProps={{ shrink: true }}
						onChange={handleImageUpload}
					/>
					<Button
						color="primary"
						variant="contained"
						fullWidth
						type="submit"
						disabled={isSubmitting}
					>
						{isSubmitting ? (
							<CircularProgress size={24} color="inherit" />
						) : (
							"Submit"
						)}
					</Button>
				</Stack>
			</form>
		</Container>
	);
}
