import { useState, useEffect } from "react";

function useFetchCategories() {
	const [categories, setCategories] = useState([]);
	const [error, setError] = useState(null);
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		const fetchCategories = async () => {
			try {
				setIsLoading(true);
				const response = await fetch(
					`${process.env.REACT_APP_API_ENDPOINT}/categories`
				);
				const data = await response.json();
				setCategories(data.masterProducts);
				setIsLoading(false);
			} catch (error) {
				setError(error);
				setIsLoading(false);
			}
		};

		fetchCategories();
	}, []);

	return { categories, error, isLoading };
}

export default useFetchCategories;
